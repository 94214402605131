import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { additionalUrlParams, getUrlParam } from "../../utils";
import { invokeADPCall, updateCase } from "../../api/crm";
import FormComponent from "../../components/Forms/FormComponent";
import ImageLayoutContainer from "../../components/ImageLayoutContainer/ImageLayoutContainer";
import { useCaseInfoContext } from "../../context/CaseContext/CaseContext";
const AcceptInitialDataTerms = () => {
  const navigate = useNavigate();
  const { CustomerDetails = {}, ...caseInfoState } = useCaseInfoContext();
  const [isLoading, setIsLoading] = useState(false);
  const textFields = [
    {
      fieldName: "acceptTermsAndConditions",
      label:
        "By ticking this box you are consenting to Custom Credit using your data in this way",
      type: "checkbox",
    },
  ];

  const handleSubmit = async () => {
    if (!CustomerDetails.ApplicantID && getUrlParam("ApplicantID")) {
      CustomerDetails.ApplicantID = getUrlParam("ApplicantID");
    }

    const adpCallNames = ["CAT 11"];
    setIsLoading(true);
    const asyncUpdatedAdpCalls = async (index: number): Promise<void> => {
      const adpCallName = adpCallNames[index];

      if (index === adpCallNames.length || !adpCallName) {
        setIsLoading(false);
        return navigate("/bank-details" + additionalUrlParams());
      }

      try {
        const { data } = await invokeADPCall({
          Payload: {
            AdpCallName: adpCallName,
            CaseRef: CustomerDetails?.ApplicantID,
          },
        });

        const recordValue = data?.d?.Records?.find((record: any) =>
          record?.FieldName?.includes(adpCallName)
        )?.Value;

        if (
          caseInfoState?.CustomerDetails?.ApplicantID ||
          getUrlParam("ApplicantID")
        ) {
          await updateCase({
            ApplicantID:
              caseInfoState?.CustomerDetails?.ApplicantID ??
              getUrlParam("ApplicantID"),
            CustomerReference:
              caseInfoState?.CustomerDetails?.CustomerReference ??
              getUrlParam("CustomerReference"),
            Payload: {
              WorkflowStep: "2.7",
              CaseStatus: "Application in Progress",
            },
          });
        }

        if (recordValue !== "Warning Live" || recordValue !== "Accept") {
          const responseData = await updateCase({
            ApplicantID:
              CustomerDetails?.ApplicantID ?? getUrlParam("ApplicantID"),
            CustomerReference:
              CustomerDetails?.CustomerReference ??
              getUrlParam("CustomerReference"),
            Payload: {
              WorkflowStep: "9",
              CaseStatus: "Declined",
            },
          });

          if (responseData?.data?.d?.UpdateResult?.SuccessCode === 0) {
            return navigate("/failed-credit-checks" + additionalUrlParams(), {
              state: {
                submitBtnURL: "/home",
                submitBtnText: "Go to Dashboard",
              },
            });
          }
        }

        const nextIndex = index + 1;
        return asyncUpdatedAdpCalls(nextIndex);
      } catch (err: any) {
        console.log(err?.message);
        setIsLoading(false);
      }
    };

    await asyncUpdatedAdpCalls(0);
  };

  return (
    <ImageLayoutContainer noPadding title="Confirmation">
      <FormComponent
        textFields={textFields}
        handleSubmit={handleSubmit}
        disableButton={isLoading}
        additionalText="In order to process your application we’ll submit your personal information to credit reference agencies (CRAs) and in return they will give us information about you, such as your financial history. We do this to assess credit worthiness, check your identity and prevent criminal activity."
      />
    </ImageLayoutContainer>
  );
};

export default AcceptInitialDataTerms;
