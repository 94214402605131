import { CircularProgress, Stack, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import useFormHook from "./Helpers/useFormHook";
import CustomTextField from "./Common/CustomTextField";
import CustomSelectInput from "./Common/CustomSelectInput";
import CustomCheckbox from "./Common/CustomCheckbox";
import * as yup from "yup";
import GradiantButton from "../GradiantButton/GradiantButton";
import NeedHelpLink from "../NeedHelpLink/NeedHelpLink";
import Button from "@mui/material/Button";
import { FocusEvent } from "react";
import CustomAutoComplete from "./Common/CustomAutoComplete.js";
import CustomDatePicker from "./Common/CustomDatePicker.js";
import CustomNumberInput from "./Common/CustomNumberInput";
import { useLocation } from "react-router-dom";
import moment from "moment";
export const getYup = yup;

export const fieldStatuses = {
  empty: "empty",
  verified: "verified",
  notVerified: "notVerified",
};

type FormComponentProps = {
  textFields: {
    fieldName: string;
    type: string;
    label?: string;
    defaultValue?: string | number;
    notValidate?: boolean;
    options?: { value: string; title: string }[];
    additionalElement?: React.ReactNode;
    customValidation?: any;
    handleBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    customChangeHandler?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
    isVerified?: string;
    onOptionClick?: (values: any) => void;
    fetchOptions?: (input: any) => void;
    extraProps?: any;
    visible?: boolean;
  }[];
  handleSubmit: (
    values: { [x: string]: string },
    formikHelpers: FormikHelpers<{ [x: string]: string }>
  ) => void | Promise<any>;
  submitBtnLabel?: string;
  additionalText?: string | React.ReactNode;
  additionalTextInEnd?: string | React.ReactNode;
  wantCenterLayout?: boolean;
  hideNeedHelpBtn?: boolean;
  loaderNewComonent?: boolean;
  disableButton?: boolean;
};

const FormComponent = (props: FormComponentProps) => {
  const { pathname } = useLocation();
  const {
    textFields,
    handleSubmit,
    submitBtnLabel,
    additionalText,
    additionalTextInEnd,
    hideNeedHelpBtn,
    loaderNewComonent,
    disableButton = false,
  } = props;
  const [formik] = useFormHook(textFields, handleSubmit);
  const handleBlur = (e: any) => {
    if (e.target.value) {
      console.log(e.target.value);
      const customHandleBlur = textFields.find(
        (item) => item.fieldName === e.target.name
      )?.handleBlur;
      if (typeof customHandleBlur === "function") customHandleBlur(e);
    }
    if (e.target.name == "DateOfBirth") {
      return;
    }
    return formik.setValues((values: any) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChange = (e: any) => {
    const customHandleChange = textFields.find(
      (item) => item.fieldName === e.target.name
    )?.customChangeHandler;
    if (typeof customHandleChange === "function") {
      if (e.target.value) {
        customHandleChange(e);
      }
    }
    return formik.handleChange(e);
  };

  return (
    <Stack
      className="!mt-12"
      flex="1"
      direction="column"
      alignItems="center"
      sx={{
        display: "flex",
        width: props?.wantCenterLayout
          ? { xl: "30%", lg: "35%", md: "357.5px", sm: "100%", xs: "100%" }
          : "100%",
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col flex-1 w-full"
        style={{
          padding: props?.wantCenterLayout ? "0px" : "0px 22px 0px 22px",
        }}
      >
        {typeof additionalText === "string" ? (
          <Typography alignSelf="flex-start" className="!mb-6 !text-xs !w-5/6">
            {additionalText}
          </Typography>
        ) : (
          additionalText
        )}

        {!loaderNewComonent ? (
          textFields.map((item) => {
            const textFieldItems = {
              ...formik,
              ...item,
              label: item.label ? item.label : undefined,
              fieldName: item.fieldName,
              type: item.type,
              value: formik.values[item.fieldName],
              onChange: handleChange,
              onBlur: handleBlur,
              error:
                formik.touched[item.fieldName] &&
                Boolean(formik.errors[item.fieldName]),
              helperText:
                (formik.touched[item.fieldName] &&
                  formik.errors[item.fieldName]) ||
                " ",
              touched: formik.touched[item.fieldName],
            };
            switch (item.type) {
              case "text":
                return (
                  <div
                    key={textFieldItems.fieldName}
                    className={
                      textFieldItems?.visible !== undefined
                        ? textFieldItems?.visible
                          ? "block"
                          : "hidden"
                        : "block"
                    }
                  >
                    {item.additionalElement}
                    <CustomTextField item={textFieldItems} />
                  </div>
                );
              case "amount-input":
                return (
                  <div
                    key={textFieldItems.fieldName}
                    className={
                      textFieldItems?.visible !== undefined
                        ? textFieldItems?.visible
                          ? "block"
                          : "hidden"
                        : "block"
                    }
                  >
                    {item.additionalElement}
                    <CustomNumberInput item={textFieldItems} />
                  </div>
                );
              case "select":
                return (
                  <div
                    key={textFieldItems.fieldName}
                    className={
                      textFieldItems?.visible !== undefined
                        ? textFieldItems?.visible
                          ? "block"
                          : "hidden"
                        : "block"
                    }
                  >
                    {item.additionalElement}
                    <CustomSelectInput item={textFieldItems} />
                  </div>
                );
              case "checkbox":
                return (
                  <div
                    key={textFieldItems.fieldName}
                    className={
                      textFieldItems?.visible !== undefined
                        ? textFieldItems?.visible
                          ? "block"
                          : "hidden"
                        : "block"
                    }
                  >
                    <Typography className="!text-xs !mt-6">
                      {item.additionalElement}
                    </Typography>
                    <CustomCheckbox item={textFieldItems} />
                  </div>
                );
              case "autocomplete":
                return (
                  <div
                    key={textFieldItems.fieldName}
                    className={
                      textFieldItems?.visible !== undefined
                        ? textFieldItems?.visible
                          ? "block"
                          : "hidden"
                        : "block"
                    }
                  >
                    {item.additionalElement}
                    <CustomAutoComplete item={textFieldItems} />
                  </div>
                );
              case "date":
                return (
                  <div
                    key={textFieldItems.fieldName}
                    className={
                      textFieldItems?.visible !== undefined
                        ? textFieldItems?.visible
                          ? "block"
                          : "hidden"
                        : "block"
                    }
                  >
                    {item.additionalElement}
                    <CustomDatePicker item={textFieldItems} />
                  </div>
                );
              default:
                return (
                  <div
                    key={textFieldItems.fieldName}
                    className={
                      textFieldItems?.visible !== undefined
                        ? textFieldItems?.visible
                          ? "block"
                          : "hidden"
                        : "block"
                    }
                  >
                    {item.additionalElement}
                    <CustomTextField item={textFieldItems} />
                  </div>
                );
            }
          })
        ) : (
          <CircularProgress
            color="primary"
            size="45px"
            style={{ margin: "auto" }}
          />
        )}
        {typeof additionalTextInEnd === "string" ? (
          <Typography alignSelf="flex-start" className="!mt-6 !text-xs">
            {additionalTextInEnd}
          </Typography>
        ) : (
          ""
        )}
      </form>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        alignSelf="flex-end"
        className="!justify-self-end"
      >
        {hideNeedHelpBtn ? (
          <Typography
            style={{ padding: "15px 22px 22px 22px", fontSize: "12px" }}
          >
            By clicking “Authorise Open Banking”, you consent to our
            <a
              href="https://customcredit.co.uk/keys-terms-and-eligibility-criteria/"
              style={{ fontWeight: "bold", padding: " 0 5px" }}
              target="_blank"
            >
              Terms and Conditions.
            </a>
            To understand how we handle your personal data, please review our
            <a
              href="https://customcredit.co.uk/privacy-policy/"
              style={{ fontWeight: "bold", padding: " 0 5px" }}
              target="_blank"
            >
              Privacy Policy.
            </a>
            If you need assistance, feel free to
            <a
              href="https://customcredit.co.uk/contact-us/"
              style={{ fontWeight: "bold", padding: " 0 5px" }}
              target="_blank"
            >
              Get Help
            </a>
          </Typography>
        ) : (
          <NeedHelpLink />
        )}
        {props?.wantCenterLayout ? (
          <Button
            className="!mt-2"
            variant="contained"
            onClick={() => formik.handleSubmit()}
            disabled={disableButton}
            fullWidth
          >
            {submitBtnLabel || "Next"}
          </Button>
        ) : (
          <GradiantButton
            handleClick={() => formik.handleSubmit()}
            disableButton={disableButton}
            btnText={
              submitBtnLabel || !hideNeedHelpBtn
                ? "Next"
                : "Authorise Open Banking"
            }
          />
        )}
      </Stack>
    </Stack>
  );
};

export default FormComponent;
